import { useMutation } from "@tanstack/react-query";
import axios from "../../libs/axios/axios";

export default function useSignInWithGoogle() {
  return useMutation({
    mutationFn: (credentials: any) => {
      return axios.post("/signin-with-google", credentials);
    },
  });
}
